import { DoneAll, Error, Image, ImageOutlined, Save } from '@mui/icons-material';
import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import Api from "../../../lib/Api";
import Global from "../../../lib/Global";
import moment from 'moment/moment';
// import DownloadTemplateProductBulk from '../DownloadTemplateProduct';
import { FileUploader } from 'react-drag-drop-files';
import { Alert, Box, Button, Checkbox, CircularProgress, Container, Divider, FormControlLabel, Grid, LinearProgress, Switch, TextField } from '@mui/material';
// import DownloadTemplateUsahaBulk from '../DownloadTemplateUsaha';

function DialogFormAdmin(props) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [isSubmitUser, setIsSubmitUser] = useState(false);
    const [errorMessageUser, setErrorMessageUser] = useState("");
    const [changePassword, setChangePassword] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    const [payloadUser, setPayloadUser] = useState({
        id: "",
        username : "",
        fullname : "",
        role : "admin",
        avatar : "",
        password: "",
        password2: "",
        access : []
    });

    const [features, setFeatures] = useState(
        [
            {label: "GA Dashboard", id: "ga_dashboard", checked: false},
            {label: "Overview", id: "overview", checked: false},
            {label: "Users", id: "users", checked: false},
            {label: "Referral", id: "referral", checked: false},
            {label: "Stores", id: "stores", checked: false},
            {label: "Products", id: "products", checked: false},
            {label: "Artikel & Tutorial", id: "artikel_tutorial", checked: false},
            {label: "Subscription", id: "subscription", checked: false},
            {label: "Subscription Setup", id: "subscription_setup", checked: false},
            {label: "Survey", id: "survey", checked: false},
            {label: "QRIS Vendor", id: "qris_vendor", checked: false},
            {label: "Gift Card", id: "gift_card", checked: false},
            {label: "Customer Service", id: "customer_service", checked: false},
            {label: "Admin Access", id: "admin_access", checked: false}
        ]
    );

    useEffect(()=>{
        
        console.log("LOG_USE_EFFECT", props.adminData);
        if(props.adminData){
            setIsEditing(true);
            setPayloadUser({
                id: props.adminData?.id,
                username : props.adminData?.username,
                fullname : props.adminData?.fullname,
                role : "admin",
                avatar : props.adminData?.avatar,
                password: "",
                password2: "",
                access : props.adminData?.access ? [...props.adminData?.access] : []
            });
            setChangePassword(false);
            let clonedFeatures = [...features];
            props.adminData?.access?.forEach(item => {
                let findFeature = clonedFeatures.find(obj => obj.id === item);
                if(findFeature){
                    findFeature.checked = true;
                }
            });
            setFeatures(clonedFeatures);
        }
    }, []);

    const handleFeatureChange = (event) => {
        const featureId = event.target.id;
        const checked = event.target.checked;

        let clonedFeatures = [...features];
        let findFeature = clonedFeatures.find(item => item.id === featureId);

        if(findFeature){
            findFeature.checked = checked;
            setFeatures(clonedFeatures);
            console.log(features);
        }

    }

    const handleChangeUserData = (event) => {
        setPayloadUser({...payloadUser, [event.target.name] : event.target.value});
    }

    const handleSubmitAddUser = () => {

        if(changePassword){
            if(payloadUser.password !== payloadUser.password2){
                setErrorMessageUser("Kedua password harus sama");
                return;
            }
    
            if(payloadUser.password === "" || payloadUser.password === null || payloadUser.password === undefined){
                setErrorMessageUser("kedua password harus diisi");
                return;
            }
        }

        if(payloadUser.fullname === "" || payloadUser.fullname === null || payloadUser.fullname === undefined){
            setErrorMessageUser("Fullname harus diisi");
            return;
        }

        if(payloadUser.username === "" || payloadUser.username === null || payloadUser.username === undefined){
            setErrorMessageUser("Username harus diisi");
            return;
        }

        let enabledFeatures = [];
        features
            .filter(feature => feature.checked === true)
            .forEach(data => {enabledFeatures.push(data.id)});

        
        // return;

        let payload = {
            username: payloadUser.username,
            fullname: payloadUser.fullname,
            role: "admin",
            avatar: "",
            access: enabledFeatures
        };

        if(changePassword){
            payload.password = payloadUser.password
        }
        console.log("PAYLOAD", payload);
        // return;

        setIsSubmitUser(true);
        
        axios
            .post(
                Api.adminAdd(),
                {user: payload},
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("SIGNUP", response.data);
                setIsSubmitUser(false);
                setErrorMessageUser("");
                props.reload();
                props.showSnackbar("User admin baru berhasil ditambahkan");
                props.onClose();
            })
            .catch((error) => {
                let errorMessage = "Tambah user error! code : " + error.response.status + ", message : " + error.response.data.message;
                setIsSubmitUser(false);
                setErrorMessageUser(errorMessage);
            });

    }

    const handleSubmitUpdateUser = () => {

        if(changePassword){
            if(payloadUser.password !== payloadUser.password2){
                setErrorMessageUser("Kedua password harus sama");
                return;
            }
    
            if(payloadUser.password === "" || payloadUser.password === null || payloadUser.password === undefined){
                setErrorMessageUser("kedua password harus diisi");
                return;
            }
        }

        if(payloadUser.fullname === "" || payloadUser.fullname === null || payloadUser.fullname === undefined){
            setErrorMessageUser("Fullname harus diisi");
            return;
        }

        if(payloadUser.username === "" || payloadUser.username === null || payloadUser.username === undefined){
            setErrorMessageUser("Username harus diisi");
            return;
        }

        let enabledFeatures = [];
        features
            .filter(feature => feature.checked === true)
            .forEach(data => {enabledFeatures.push(data.id)});

        
        // return;

        let payload = {
            username: payloadUser.username,
            fullname: payloadUser.fullname,
            role: "admin",
            avatar: "",
            access: enabledFeatures,
            // id: payloadUser.id
        };

        if(changePassword){
            payload.password = payloadUser.password
        }
        console.log("PAYLOAD", payload);
        setErrorMessageUser("Maaf, Fungsi edit admin belum diimplementasikan");
        // return;

        setIsSubmitUser(true);
        
        axios
            .put(
                Api.adminUpdate(payloadUser.id),
                payload,
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("SIGNUP", response.data);
                setIsSubmitUser(false);
                setErrorMessageUser("");
                props.reload();
                props.showSnackbar("User admin berhasil diupdate");
                props.onClose();
            })
            .catch((error) => {
                let errorMessage = "Tambah user error! code : " + error.response.status + ", message : " + error.response.data.message;
                setIsSubmitUser(false);
                setErrorMessageUser(errorMessage);
            });

    }

    const handleSubmitForm = () => {
        if(isEditing){
            handleSubmitUpdateUser();
        }else{
            handleSubmitAddUser();
        }
    }

    return <Container>
        {(errorMessageUser && errorMessageUser.length > 0) && <Alert severity='error' sx={{mb: "10px"}}>{errorMessageUser}</Alert>}
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="fullname"
                            name="fullname"
                            label="Fullname"
                            value={payloadUser?.fullname}
                            type='text'
                            fullWidth
                            size='small'
                            onChange={handleChangeUserData}
                            sx={{mt: "5px"}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="username"
                            name="username"
                            label="Username"
                            value={payloadUser?.username}
                            type='text'
                            fullWidth
                            size='small'
                            onChange={handleChangeUserData}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sx={{textAlign: "right", padding: 0}}></Grid> */}
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "right"}}>
                            <FormControlLabel control={<Checkbox onChange={(event) => setChangePassword(event.target.checked)} size='small' checked={changePassword} />} label="Ubah Password" />
                        </Box>
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            defaultValue={""}
                            type='password'
                            fullWidth
                            size='small'
                            onChange={handleChangeUserData}
                            disabled={!changePassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password2"
                            name="password2"
                            label="Password (*ulangi password diatas)"
                            defaultValue={""}
                            type='password'
                            fullWidth
                            size='small'
                            onChange={handleChangeUserData}
                            disabled={!changePassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            onClick={handleSubmitForm} 
                            variant='contained' 
                            color='primary' 
                            fullWidth 
                            disabled={isSubmitUser}
                        >
                            {isSubmitUser ? <CircularProgress size={20} color='info' /> : <Save />} &nbsp; Simpan Admin 
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={1}>
                <Divider orientation='vertical' sx={{ml: "5px", mr: "5px"}} />
            </Grid>
            <Grid item xs={8}>
                <Box sx={{fontWeight: "bold"}}>Ijin Akses Fitur</Box>
                <Divider orientation='horizontal' sx={{mt: "10px", mb: "10px"}} />
                <Grid container spacing={2}> 
                {
                    features.map(item => {
                        return (
                            <Grid key={`grid${item.id}`} item xs={4}>
                                <FormControlLabel sx={{fontSize: "7px"}} control={<Switch id={item.id} name={item.id} onChange={handleFeatureChange} checked={item.checked} size='small' />} label={item.label} />
                            </Grid>
                        );
                    })
                }
                </Grid>
                
            </Grid>
        </Grid>
        
    </Container>;
}

export default DialogFormAdmin;