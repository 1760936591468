import { Box, Button, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Api from "../../lib/Api";
import DataTable from 'react-data-table-component';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import "./GoogleAnalytics.css";
import { InfoRounded, PictureAsPdf } from '@mui/icons-material';
import LineChart from './LineChart';
import PieChart from './PieChart';
import BarChart from './BarChart';

const defaultLabel = [];
class DashboardGA extends Component {
    state = { 
        isSignedIn: false,
        isLoading: false,
        gaReportData: [],
        startDate: moment(new Date()).subtract(1, "Y"),
        endDate: moment(new Date()),
        // startDate: moment(new Date()).subtract(1, "Y").format("YYYY-MM-DD"),
        // endDate: moment(new Date()).format("YYYY-MM-DD"),
        totalActiveUsers: 0,
        activeUsersData: {labels: defaultLabel, datasets: []},
        screenPageViewsData: {labels: defaultLabel, datasets: []},
        deviceCategoryData: {labels: defaultLabel, datasets: []},
        osCategoryData: {labels: defaultLabel, datasets: []},
        kategoriLanggananData: {labels: defaultLabel, datasets: []},
        storeData: {labels: defaultLabel, datasets: []},
        transaksiData: {labels: defaultLabel, datasets: []},
        avgSessionTimeData: {labels: defaultLabel, datasets: []},
        chartSessionsData: {labels: defaultLabel, datasets: []},
        crashFreeUsers: 0,
        wauPerMau: 0,
        tabValue: "1",
        activeUsersPlainData: [],
        screenPageViewPlainData: [],
        jumlahToko: 0,
        storeTransactions: [],
        totalToko: 0,
        totalPengguna: 0,
        totaUserBaru: 0,
        avgSessions: 0,
        activeSessions: 0
    } 

    // defaultLabel = [];

    colors = () => {
      return [
        'rgba(28, 254, 156, 0.7)',
        'rgba(244, 182, 232, 0.7)',
        'rgba(88, 224, 68, 0.7)',
        'rgba(13, 133, 22, 0.7)',
        'rgba(66, 21, 49, 0.7)',
        'rgba(15, 61, 144, 0.7)',
        'rgba(63, 71, 53, 0.7)',
        'rgba(110, 47, 103, 0.7)',
        'rgba(251, 143, 5, 0.7)',
        'rgba(230, 65, 106, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ];
    };

    gaReportActiveusers = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          {
            name: 'city',
          },
          {
            name: 'country',
          },
        ],
        metrics: [
          {
            name: 'activeUsers',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORT", response.data);

          var totalActiveUsers = 0;
          var dataActiveUsers = [];
          var labels = [];
          var data = [];
          var i = 1;
          var plainData = [];
          response.data.rows.forEach(element => {
            
            // dataActiveUsers.push(
            //   {
            //       label: element.dimensionValues[0].value,
            //       data: element.metricValues[0].value,
            //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //   }
            // );
            if(i <= 10){
              data.push(element.metricValues[0].value);
              labels.push(element.dimensionValues[0].value);
              plainData.push({dimension: element.dimensionValues[0].value, metric: element.metricValues[0].value});
              ++i;
            }
            
            totalActiveUsers += parseInt(element.metricValues[0].value);
          });

          dataActiveUsers.push(
            {
                label: "",
                data: data,
                // backgroundColor: this.colors(),
                backgroundColor: "#165BAA",
            }
        );

          this.setState({isLoading: false, activeUsersPlainData: plainData, totalActiveUsers: totalActiveUsers, gaReportData: response.data.rows, activeUsersData: {labels: labels, datasets: dataActiveUsers}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gaReportScreenPageViews = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          {
            name: 'unifiedScreenName',
          },
          // {
          //   name: "engagementTime"
          // }
        ],
        metrics: [
          {
            name: 'screenPageViews',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTscreenPageViews", response.data);

          var dataScreenPageViews = [];
          var labels = [];
          var data = [];
          var i = 1;
          var plainData = [];
          response.data.rows.forEach(element => {
            if(i <= 10){
              data.push(element.metricValues[0].value);
              labels.push(element.dimensionValues[0].value);
              plainData.push({dimension: element.dimensionValues[0].value, metric: element.metricValues[0].value});
              ++i;
            }
          });

          dataScreenPageViews.push(
            {
                label: "",
                data: data,
                // backgroundColor: this.colors(),
                backgroundColor: "#165BAA",
            }
        );

          this.setState({isLoading: false, screenPageViewPlainData: plainData, screenPageViewsData: {labels: labels, datasets: dataScreenPageViews}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportDeviceModels = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          {
            name: 'deviceCategory',
          }
        ],
        metrics: [
          {
            name: 'activeUsers',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTdeviceCategory", response.data);

          var deviceCategory = [];
          var labels = [];
          var data = [];
          response.data.rows.forEach(element => {
            data.push(element.metricValues[0].value);
            labels.push(element.dimensionValues[0].value);
          });

          deviceCategory.push(
            {
                label: "",
                data: data,
                backgroundColor: this.colors(),
                borderColor: this.colors(),
                borderWidth: 1
            }
        );

          this.setState({isLoading: false, deviceCategoryData: {labels: labels, datasets: deviceCategory}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportOs = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          {
            name: 'operatingSystem',
          },
          // {
          //   name: 'averageSessionDuration',
          // }
        ],
        metrics: [
          {
            name: 'activeUsers',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORT_OS", response.data);

          var deviceCategory = [];
          var labels = [];
          var data = [];
          response.data.rows.forEach(element => {
            data.push(element.metricValues[0].value);
            labels.push(element.dimensionValues[0].value);
          });

          deviceCategory.push(
            {
                label: "",
                data: data,
                backgroundColor: this.colors(),
                borderColor: this.colors(),
                borderWidth: 1
            }
        );

          this.setState({isLoading: false, osCategoryData: {labels: labels, datasets: deviceCategory}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };
    gareportAvgSessionsTotal = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          // {
          //   name: 'operatingSystem',
          // },
          // {
          //   name: 'averageSessionDuration',
          // }
        ],
        metrics: [
          {
            name: 'averageSessionDuration',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORT_SESSIONS", response.data);

          let avgSessions = 0;
          response.data.rows.forEach(element => {
            avgSessions += parseInt(element.metricValues[0].value);
            // data.push(element.metricValues[0].value);
            // labels.push(element.dimensionValues[0].value);
          });

          let menit = (avgSessions/60).toFixed();
          let detik = avgSessions%60;

          this.setState({isLoading: false, avgSessions: `${menit}m ${detik}s`});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareporActiveSessions = () => {
      const params = {
        dateRanges: [
          {
            startDate: moment().tz("asia/jakarta").format("YYYY-MM-DD"),
            endDate: moment().tz("asia/jakarta").format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          // {
          //   name: 'operatingSystem',
          // },
          // {
          //   name: 'averageSessionDuration',
          // }
        ],
        metrics: [
          {
            name: 'sessions',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORT_ACTIVE_USERS", response.data);

          let activeSession = 0;
          response.data.rows.forEach(element => {
            activeSession += parseInt(element.metricValues[0].value);
            // data.push(element.metricValues[0].value);
            // labels.push(element.dimensionValues[0].value);
          });

          this.setState({isLoading: false, activeSessions: activeSession});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareporChartSessions = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          {
            name: 'month',
          },
          {
            name: 'year',
          },
          // {
          //   name: 'averageSessionDuration',
          // }
        ],
        metrics: [
          {
            name: 'sessions',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORT_CHART_SESSION", response.data);

          var deviceCategory = [];
          var labels = [];
          var data = [];
          response.data.rows.forEach(element => {
            data.push(element.metricValues[0].value);
            labels.push(`${element.dimensionValues[1].value}-${element.dimensionValues[0].value}`);
          });

          labels.sort();

          deviceCategory.push(
            {
                label: "",
                data: data,
                backgroundColor: this.colors(),
                borderColor: this.colors(),
                borderWidth: 1
            }
        );

          this.setState({isLoading: false, chartSessionsData: {labels: labels, datasets: deviceCategory}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportCrashFreeUsers = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          // {
          //   name: 'deviceCategory',
          // }
        ],
        metrics: [
          {
            name: 'crashFreeUsersRate',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTCrashFree", response.data);

          var crashFreeValue = 0;
          response.data.rows.forEach(element => {
            crashFreeValue = element.metricValues[0].value*100;
          });

          this.setState({isLoading: false, crashFreeUsers: crashFreeValue});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportWauPerMau = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD"),
          },
        ],
        dimensions: [
          // {
          //   name: 'deviceCategory',
          // }
        ],
        metrics: [
          {
            name: 'wauPerMau',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTwauPerMauValue", response.data);

          var wauPerMauValue = 0;
          response.data.rows.forEach(element => {
            wauPerMauValue = element.metricValues[0].value*100;
          });

          this.setState({isLoading: false, wauPerMau: wauPerMauValue});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };
    
    componentDidMount(){

      ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ArcElement,
        Title,
        Tooltip,
        Legend
      );

        this.gareportOs();
        this.gareportAvgSessionsTotal();
        this.gaReportActiveusers();
        this.gareporActiveSessions();
        this.gareporChartSessions();
        this.gaReportScreenPageViews();
        this.gareportDeviceModels();
        this.gareportCrashFreeUsers();
        this.gareportWauPerMau();
        this.loadPossakuData();
        this.loadTopStatistics();
        this.loadUsers();
    }

    handleDateChanged = (rangeDate) => {

      // let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      // let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
      let startDate =  moment(rangeDate[0]);
      let endDate =  moment(rangeDate[1]);

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => {
        this.gaReportActiveusers(); 
        this.gaReportScreenPageViews();
        this.gareportDeviceModels();
        this.gareportCrashFreeUsers();
      });
    }

    dataTableColumns = () => {
      return [
          {
              name: "City",
              selector: row => row.dimensionValues[0].value,
              sortable: true
          },
          {
              name: "Country",
              selector: row => row.dimensionValues[1].value,
              sortable: true
          },
          {
              name: "Count",
              selector: row => row.metricValues[0].value,
              sortable: true
          }
      ];
    }

    optionsActiveUsers = {
      responsive: true,
      // indexAxis: 'y',
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Top 10 Active Users',
        },
      },
  };
  optionsScreenPageViews = {
      responsive: true,
      // indexAxis: 'y',
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Top 10 Screen Page Views',
        },
      },
  };
  optionsDeviceCategory = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Device category',
        },
      },
  };

  handleTabChange = (event, newValue) => {
    this.setState({tabValue: newValue});
  }

  loadPossakuData = () => {

    const params = {
      filter: {
          dateStart: this.state.startDate.format("YYYY-MM-DD"),
          dateEnd: this.state.endDate.format("YYYY-MM-DD")
      },
      sort: {
          _id: -1
      },
      limit: 20
    };

    axios
      .post(
        Api.dashboardSupport(),
        params,
        Api.getRequestHeader()
      )
      .then((response) => {

        const data = response.data;

        //Kategori Langganan
        const kategoriLangganan = data.chartKetegori;
        const cloneKategoriLangganan = {...this.state.kategoriLanggananData};
        let datasetsData = [];
        cloneKategoriLangganan.datasets = [];
        cloneKategoriLangganan.labels = [];
        kategoriLangganan.forEach(item => {
          cloneKategoriLangganan.labels.push(item._id.subscribe_type);
          datasetsData.push(item.count);
        });

        cloneKategoriLangganan.datasets = [
          {
            label: "",
            data: datasetsData,
            backgroundColor: this.colors(),
            // backgroundColor: "#165BAA",
          }
        ];
        // End kategori langganan

        //Jumlah Toko
        const chartToko = data.chartToko;
        const cloneStoreData = {...this.state.storeData};
        let datasetsDataToko = [];
        cloneStoreData.datasets = [];
        cloneStoreData.labels = [];
        let jumlahToko = 0;
        chartToko.forEach(item => {
          let splitYear = `${item._id.year}`.split("");
          cloneStoreData.labels.push(`${splitYear[2]}${splitYear[3]}-${item._id.month}`);
          datasetsDataToko.push(item.count);
          jumlahToko += item.count;
        });
        cloneStoreData.labels.sort();
        cloneStoreData.datasets = [
          {
            label: "",
            data: datasetsDataToko,
            backgroundColor: this.colors(), 
            // backgroundColor: "#165BAA",
          }
        ];
        // End Jumlah toko
        
        //Jumlah Toko
        const chartTransaksi = data.chartTransaksi;
        const cloneTransaksiData = {...this.state.storeTransactions};
        let datasetsDataTransaksi = [];
        cloneTransaksiData.datasets = [];
        cloneTransaksiData.labels = [];
        let jumlahTransaksi = 0;
        let storeTrans = [];
        let i = 1;
        chartTransaksi.forEach(item => {
          if(i <= 10){
            cloneTransaksiData.labels.push(`${item.storess[0]?.nama_toko}`);
            datasetsDataTransaksi.push(item.count);
            jumlahTransaksi += item.count;
            storeTrans.push({
              nama_toko: item.storess[0]?.nama_toko,
              jumlah_transaksi: item.count
            });
          }
          ++i;
        });

        cloneTransaksiData.datasets = [
          {
            label: "",
            data: datasetsDataTransaksi,
            backgroundColor: this.colors(), 
            // backgroundColor: "#165BAA",
          }
        ];
        // End Jumlah toko

        this.setState({
          kategoriLanggananData: cloneKategoriLangganan, 
          storeData: cloneStoreData, 
          jumlahToko: jumlahToko,
          storeTransactions: storeTrans
        }, () => {
          console.log("LOG___langganan", this.state.kategoriLanggananData, "storeData", cloneStoreData, "trans_data", this.state.storeTransactions);
        });

      }).catch((error) => {
        console.error("There was an error!", error);
        this.setState({isLoading: false});
      });
  }

  loadTopStatistics = () => {
    const params = {
        // filter:{store_id: "possax" },
        // sort:{nama: 1},
        // limit:100
    };

    console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());

    // axios.get("https://randomuser.me/api/?results=20")
    axios
      .get(Api.topStatistics())
      .then((response) => {
        console.log("TOPSTATISTICS", response.data);
        const responseData = response.data;
        
        var totalToko = responseData.countStore;
        var totalPengguna = responseData.countUser;


        console.log("TOPETALASE : ", responseData.topEtalase)
        this.setState({
            totalToko: totalToko, 
            totalPengguna: totalPengguna, 
            topEtalase: responseData.topEtalase
        });
        
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  loadUsers = () => {

    let params = {
        dateStart: moment().subtract(30, "days").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD")
    };

    console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());

    this.setState({isLoading: true}, () => {
        axios
      .post(
        Api.countUser(),
        params,
        Api.getRequestHeader()
      )
      .then((response) => {
        console.log("LISTOWNER", response.data);
        const responseData = response.data;
        const userDatas = [];
        
        var currentDate = moment().format("YYYYMMDD");
        console.log("HARIINI_currdate", currentDate);

        // let jumlahOwner = responseData.data.filter((element) => element.created_at !== undefined && moment(element.created_at).format("YYYYMMDD") === currentDate && element.role === "owner store").length;
        // let userHariIni = responseData.data.filter((element) => moment(element.created_at).format("YYYYMMDD") === currentDate);
        // let jumlahKaryawan = responseData.data.filter((element) => element.created_at !== undefined &&  moment(element.created_at).format("YYYYMMDD") === currentDate && element.role !== "owner store").length;
        
        let jumlahOwner = responseData.countOwner.length > 0 ? responseData.countOwner[0].COUNT : 0;
        let jumlahKaryawan = responseData.countKasir.length > 0 ? responseData.countKasir[0].COUNT : 0;

        this.setState({totalUserBaru: jumlahOwner + jumlahKaryawan}, () => console.log("DATAUSER",this.state.dataUser));
        // console.log("USERHARIINI", userHariIni);
        
      })
      .catch((error) => {
        console.error("There was an error!", error);
        this.setState({isLoading: false});
      });
    });
    
  }

    render() { 
      return (
        <div className="rounded" style={{padding: 5}}>
          
          {/* Start of filter */}
          <div className="row">
            <div className="col-4">
              <span style={{fontWeight: "bold", fontSize: "20px"}}>Data Analytics</span>
            </div>
            <div className="col-8" style={{backgroundColor: ""}}>
              <div className="row">
                <div className="col-3">
                  <FormControl size='small' fullWidth>
                    <InputLabel id="cb-lokasi">Lokasi</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={"Semua"}
                      label="Lokasi"
                      onChange={(event) => {}}
                    >
                      <MenuItem value={"Semua"}>Semua</MenuItem>
                      <MenuItem value={20}>?</MenuItem>
                      <MenuItem value={30}>??</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6 text-center">
                  <small>Durasi</small>&nbsp;
                  <MyDateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onDateChanged={this.handleDateChanged}/>
                </div>
                <div className="col-3">
                  <Button fullWidth variant='outlined' color='primary' size='small'>
                    Export <PictureAsPdf />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* End of filter */}
          <br />
          {/* Start Summary */}
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2" style={{border: "solid #dededd",borderRightWidth: "1px", borderLeftWidth: "0", borderTopWidth: "0", borderBottomWidth: "0"}}>
                  <div className="container text-center rounded" style={{backgroundColor: "", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.totalPengguna}
                      {/* xxx */}
                    </h3>
                    {/* <strong>Active Users</strong> */}
                    <strong>Total Users</strong>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2" style={{border: "solid #dededd",borderRightWidth: "1px", borderLeftWidth: "0", borderTopWidth: "0", borderBottomWidth: "0"}}>
                  <div className="container text-center rounded" style={{backgroundColor: "", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.totalUserBaru}
                    </h3>
                    <strong>New Users</strong>
                    <div style={{fontSize: "8px"}}>*last 30 days</div>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2" style={{border: "solid #dededd",borderRightWidth: "1px", borderLeftWidth: "0", borderTopWidth: "0", borderBottomWidth: "0"}}>
                  <div className="container text-center rounded" style={{backgroundColor: "", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.avgSessions}
                    </h3>
                    <strong>Avg. Sessions Time</strong>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2" style={{border: "solid #dededd",borderRightWidth: "1px", borderLeftWidth: "0", borderTopWidth: "0", borderBottomWidth: "0"}}>
                  <div className="container text-center rounded" style={{backgroundColor: "", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.wauPerMau.toFixed(2)}%
                    </h3>
                    <strong>Wau per Mau</strong>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2" style={{border: "solid #dededd",borderRightWidth: "0px", borderLeftWidth: "0", borderTopWidth: "0", borderBottomWidth: "0"}}>
                  <div className="container text-center rounded" style={{backgroundColor: "", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.crashFreeUsers.toFixed(2).toString().replace(".00", "")}%
                    </h3>
                    <strong>Crash free users</strong>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2">
                  <Paper elevation={5} >
                    <div className="container text-center rounded" style={{backgroundColor: "", padding: "10px"}}>
                      <h3 className='text-primary'>
                        {this.state.activeSessions}
                      </h3>
                      <strong>Active Sessions</strong>
                      <div style={{fontSize: "8px"}}>*today</div>
                    </div>
                  </Paper>
                  
                </div>
              </div>
            </div>
            {/* <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <MyDateRangePicker onDateChanged={this.handleDateChanged}/>
            </div> */}
          </div>
          {/* End Summary */}

          {/* <Paper style={{padding: 10}}> */}

          {
            this.state.isLoading ? (<LinearProgress color="primary" style={{marginTop: 10}} />) : (<></>)
          }
            
            {/* <DataTable 
                title={"Active Users PosSaku"} 
                data={this.state.gaReportData} 
                columns={this.dataTableColumns()} 
                // options={this.options}
                pagination
                progressPending={this.state.isLoading}
            /> */}
          {/* </Paper> */}
          <br /><br />
          <div className="row">
            <div className="col-6">
              <Paper elevation={2} sx={{padding: "10px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-11" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Jumlah Toko
                    </Box>
                    <Box>
                      <small>{this.state.jumlahToko}</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <Divider />
                <LineChart data={this.state.storeData} />
              </Paper>
            </div>
            <div className="col-6">
              <Paper elevation={2} sx={{padding: "10px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-11" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Average Session Time
                    </Box>
                    <Box>
                      <small>{this.state.avgSessions}</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <Divider />
                <LineChart data={this.state.chartSessionsData} />
              </Paper>
            </div>
          </div>
          {/* ---------------------- */}
          <div className="row" style={{marginTop: "30px", marginBottom: "10px"}}>
            <div className="col-4">
              <Paper elevation={2} sx={{padding: "10px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Kategori Perangkat
                    </Box>
                    <Box>
                      <small>Jenis Kategori Perangkat</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <PieChart data={this.state.deviceCategoryData} />
              </Paper>
            </div>
            <div className="col-4">
              <Paper elevation={2} sx={{padding: "10px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Kategori Langganan
                    </Box>
                    <Box>
                      <small>Perbandingan langganan toko</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <PieChart data={this.state.kategoriLanggananData} />
              </Paper>
            </div>
            <div className="col-4">
              <Paper elevation={2} sx={{padding: "10px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Kategori OS Pengguna
                    </Box>
                    <Box>
                      <small>&nbsp;</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <PieChart data={this.state.osCategoryData} />
              </Paper>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Paper elevation={2} sx={{padding: "10px", mt: "10px", mb: "20px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Lokasi Toko
                    </Box>
                    <Box>
                      <small>Top 5 toko berdasarkan lokasi</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <BarChart data={this.state.activeUsersData} />
              </Paper>
              <Paper elevation={2} sx={{padding: "10px", mt: "10px", mb: "20px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Halaman dipakai
                    </Box>
                    <Box>
                      <small>Top 10 halaman yang paling banyak digunakan</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <BarChart data={this.state.screenPageViewsData} />
              </Paper>
            </div>
            <div className="col-6">
              <Paper elevation={2} sx={{padding: "10px", mt: "10px", mb: "20px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Transaksi kasir
                    </Box>
                    <Box>
                      <small>Daftar ranking transaksi terbanyak</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <Table size='small'>
                  <TableHead>
                    <TableCell>Rank.</TableCell>
                    <TableCell>Toko</TableCell>
                    <TableCell>Jumlah Transaksi</TableCell>
                  </TableHead>
                  <TableBody>
                    {
                      this.state.storeTransactions.map((item, index) => (
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.nama_toko}</TableCell>
                            <TableCell>
                              {
                                new Intl.NumberFormat('id-ID', { maximumSignificantDigits: 3 })
                                  .format(item.jumlah_transaksi,)
                              }
                            </TableCell>
                          </TableRow>
                        )
                      )
                    }
                  </TableBody>
                </Table>
              </Paper>
              <Paper elevation={2} sx={{padding: "10px", mt: "10px", mb: "20px"}}>
                <div className="row" style={{padding: "10px"}}>
                  <div className="col-10" style={{color: "#A1A1A1"}}>
                    <Box style={{fontWeight: "bold"}}>
                      Halaman Aplikasi
                    </Box>
                    <Box>
                      <small>Ranking halaman yang paling banyak digunakan</small>
                    </Box>
                  </div>
                  <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                </div>
                <Table size='small'>
                  <TableHead>
                    <TableCell>Rank.</TableCell>
                    <TableCell>Halaman</TableCell>
                    <TableCell>Jumlah Session</TableCell>
                  </TableHead>
                  <TableBody>
                    {this.state.screenPageViewPlainData.map((row, index) => (
                      <TableRow>
                        <TableCell>{index+1}.</TableCell>
                        <TableCell>{row.dimension}</TableCell>
                        <TableCell>
                        {
                          new Intl.NumberFormat('id-ID', { maximumSignificantDigits: 3 })
                            .format(row.metric,)
                        }
                        </TableCell>
                      </TableRow>
                    ))}
                    
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
              <div style={{padding: "10px"}}>
                <div className="row">
                  <div className='col-10'><strong>City</strong></div>
                  <div className='col-2'><strong>Users</strong></div>
                </div>
                <hr />
                  {this.state.activeUsersData.datasets[0] !== undefined ? (
                    <div>
                      {this.state.activeUsersPlainData.map((row) => (
                          <div className='row'>
                            <div className='col-10'>
                              {row.dimension}
                            </div>
                            <div className='col-2'>{row.metric}</div>
                          </div>
                        ))}
                    </div>
                  ) : (<></>)}
              </div>
            </div>
            <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
              <Paper style={{padding: 10}}>
                {
                  this.state.activeUsersData.datasets !== undefined ? (
                    <Bar options={this.optionsActiveUsers} data={this.state.activeUsersData} style={{maxHeight: 300}}/>
                  ) : (<></>)
                }
              </Paper>
            </div>
          </div> */}
          {/* <br />
          <hr />
          <br /> */}
          {/* Screen views Start*/}
            {/* <div className="row">
              <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
              <Paper style={{padding: 10, marginTop: 25}}>
              {
                this.state.screenPageViewsData.datasets !== undefined ? (
                  <Bar options={this.optionsScreenPageViews} data={this.state.screenPageViewsData} style={{maxHeight: 250}} />
                ) : (<></>)
              }
              </Paper>
              </div>
              <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                <div style={{padding: "10px"}}>
                    <div className="row">
                      <div className='col-10'><strong>Screen</strong></div>
                      <div className='col-2'><strong>Views</strong></div>
                    </div>
                    <hr />
                      {this.state.screenPageViewsData.datasets[0] !== undefined ? (
                        <div>
                          {this.state.screenPageViewPlainData.map((row) => (
                              <div className='row'>
                                <div className='col-10'>
                                  {row.dimension}
                                </div>
                                <div className='col-2'>{row.metric}</div>
                              </div>
                            ))}
                        </div>
                      ) : (<></>)}
                  </div>
                </div>
              </div> */}
          {/* Screen views End*/}
          
          <br />
          
          <br />
          {/* <Paper style={{padding: 10}}>
            {
              this.state.deviceCategoryData.datasets !== undefined ? (
                <Doughnut options={this.optionsDeviceCategory} data={this.state.deviceCategoryData} style={{maxHeight: 250}}/>
              ) : (<></>)
            }
          </Paper> */}
        </div>
      );
    }
}
 
export default DashboardGA;

function MyDateRangePicker(props){
  const [dateRange, setDateRange] = React.useState(
    props.startDate && props.endDate ? [props.startDate.toDate(), props.endDate.toDate()] :
    [moment(new Date()).subtract(1, "Y").toDate(), Date.now()]
  );
  const [startDate, endDate] = dateRange;
  const [message, setMessage] = React.useState("");

  return (
    <>
      <ReactDatePicker
        readOnly={false}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        dateFormat="d-M-yyyy"
        onChange={(update) => {
          console.log("RANGE", update);
          
          if(update[0] !== null && update[1] !== null){
            let startDate = moment(update[0]);
            let endDate = moment(update[1]);
            let diffs = Math.abs(startDate.diff(endDate, "days"));
            console.log("DIFFS", diffs);
            // if(diffs > 35){
            //   setDateRange([null, null]);
            //   setMessage("Rentang tanggal max 35 hari");
            //   return
            // }
          }
          
          setDateRange(update);
          setMessage("");
          if(update[0] !== null && update[1] !== null){
            props.onDateChanged(update);
          }

        }}
        isClearable={false}
        popperPlacement="top-end"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 10],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
        style={{borderColor: "#FFFFFF"}} 
        wrapperClassName="my-datepicker"
        className='border rounded rounded-lg border-secondary p-1 text-center'
      />
      <div className="text-danger">
        {message}
      </div>
    </>
  );
}