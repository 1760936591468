import { DoneAll, Error, Image, ImageOutlined, Save } from '@mui/icons-material';
import axios from 'axios';
import React, { Component, useState } from 'react';
import Api from "../../../lib/Api";
import Global from "../../../lib/Global";
import moment from 'moment/moment';
import DownloadTemplateProductBulk from '../DownloadTemplateProduct';
import { FileUploader } from 'react-drag-drop-files';
import { Alert, Box, Button, CircularProgress, LinearProgress, TextField } from '@mui/material';
import DownloadTemplateUsahaBulk from '../DownloadTemplateUsaha';

function DialogTambahUsaha(props) {

    const [file, setFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [isSubmitUser, setIsSubmitUser] = useState(false);
    const [errorMessageUser, setErrorMessageUser] = useState("");

    

    const dropArea = () => {
        return (
        <div>
            {
                file === null && <div className='text-center' style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <ImageOutlined /><br /><br /><h5 className='text-primary'>Upload File Template</h5>Browse atau Drop file template usaha disini
                </div>
            }

            {
                file !== null && <div className='text-center' style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <Image className='text-success' /><br />{file.name}
                </div>
            }
        </div>
        );
    }

    const handleFileChange = (file) => {
        setFile(file);
    }

    const uploadTemplateUsahaBulk = () => {
        const data = new FormData();
        data.append("users", file);
        setIsSubmitting(true);
        axios
            .post(Api.uploadUserBulk(), data, Api.getRequestHeader(true))
            .then((response) => {
                console.log("RESPONSE_AXIOS", response.data);
                let responseData = response.data;
                if(responseData){
                    if(responseData.success === true){
                        setIsSubmittedSuccessfully(true);
                        setErrorMessage([]);
                    }else{
                        let errors = responseData.result.err;
                        let mappedErrors = [];
                        errors.forEach(item => {
                            let data = item.data;
                            mappedErrors.push(data.user.fullname + " (" + data.user.ussd + ") : " + item.error);
                        });
                        setErrorMessage(mappedErrors);
                    }
                }

                // if (response.status === 200 || response.status === 201) {
                //     setIsSubmittedSuccessfully(true);
                // }else{
                //     setIsSubmittedSuccessfully(false);
                // }
                
                setIsSubmitting(false);
            })
            .catch((error) => {
                setIsSubmitting(false);
                setIsSubmittedSuccessfully(false);

                let errMsgs = [...errorMessage];
                errMsgs.push(error.message);
                setErrorMessage(errMsgs);
            });
    }

    const clearSelectedFile = () => {
        setFile(null);
    }

    const [payloadUser, setPayloadUser] = useState({
        username : "",
        fullname : "",
        ussd : "",
        email:"",
        pin : 123456,
        avatar : "",
        role : "owner store",
        google_id : "",
        fb_id : "",
        device_id : ""
    });

    const [payloadStore, setPayloadStore] = useState({
        nama_toko:"",
        alamat:"",
        lokasi:"0.0,0.0",
        cashier:0
    });

    const handleChangeUserData = (event) => {
        setPayloadUser({...payloadUser, [event.target.name] : event.target.value});
    }

    const handleChangestoreData = (event) => {
        setPayloadStore({...payloadStore, [event.target.name] : event.target.value});
    }

    const handleSubmitRegisterUser = () => {

        if(typeof payloadUser.pin === "number" && payloadUser.pin > 999999){
            setErrorMessageUser("Pin tidak boleh lebih dari 6 digit");
            return;
        }

        if(typeof payloadUser.pin === "string" && payloadUser.pin.length > 6){
            setErrorMessageUser("Pin tidak boleh lebih dari 6 digit");
            return;
        }
        // console.log("PAYLOAD", payloadUser);
        // return;
        setIsSubmitUser(true);
        payloadUser.username = payloadUser.fullname.replaceAll(" ", "_").toLowerCase();
        if(typeof payloadUser.pin === "string"){
            payloadUser.pin = parseInt(payloadUser.pin);
        }

        axios
            .post(
                Api.addUser(),
                {user: payloadUser, store: payloadStore},
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("SIGNUP", response.data);
                setIsSubmitUser(false);
                setErrorMessageUser("");
                props.showSnackbar("User/Usaha baru berhasil ditambahkan");
            })
            .catch((error) => {
                let errorMessage = "Tambah user error! code : " + error.response.status + ", message : " + error.response.data.message;
                setIsSubmitUser(false);
                setErrorMessageUser(errorMessage);
            });

    }

    return <>
        <div className="row" style={{marginBottom: "10px", marginTop: "10px"}}>
            <div className="col-6">
                <TextField
                    id="fullname"
                    name="fullname"
                    label="Fullname"
                    defaultValue={""}
                    type='text'
                    fullWidth
                    size='small'
                    onChange={handleChangeUserData}
                />
            </div>
            <div className="col-6">
                <TextField
                    id="ussd"
                    name="ussd"
                    label="No HP"
                    defaultValue={""}
                    type='tel'
                    fullWidth
                    size='small'
                    onChange={handleChangeUserData}
                />
            </div>
        </div>
        <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-6">
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    defaultValue={""}
                    type='email'
                    fullWidth
                    size='small'
                    onChange={handleChangeUserData}
                />
            </div>
            <div className="col-6">
                <TextField
                    id="pin"
                    name="pin"
                    label="Pin"
                    defaultValue={""}
                    type='number'
                    fullWidth
                    size='small'
                    onChange={handleChangeUserData}
                />
            </div>
        </div>
        <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-6">
                <TextField
                    id="nama_toko"
                    name="nama_toko"
                    label="Nama Toko"
                    defaultValue={""}
                    type='text'
                    fullWidth
                    size='small'
                    onChange={handleChangestoreData}
                />
            </div>
            <div className="col-6">
                <TextField
                    id="alamat"
                    name="alamat"
                    label="Alamat"
                    defaultValue={""}
                    type='text'
                    fullWidth
                    size='small'
                    onChange={handleChangestoreData}
                />
            </div>
        </div>
        {/* <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-6">
                <TextField
                    id="txtTag"
                    label="Tag"
                    defaultValue={""}
                    type='text'
                    fullWidth
                    size='small'
                />
            </div>
            <div className="col-6">
                <TextField
                    id="txtLabel"
                    label="Label"
                    defaultValue={""}
                    type='text'
                    fullWidth
                    size='small'
                />
            </div>
        </div> */}
        <div className="row">
            <div className="col-6">
                {(errorMessageUser && errorMessageUser.length > 0) && <Alert severity='error'>{errorMessageUser}</Alert>}
            </div>
            <div className="col-6">
                <Button onClick={handleSubmitRegisterUser} variant='contained' color='primary' fullWidth disabled={isSubmitUser}>{isSubmitUser ? <CircularProgress size={20} color='info' /> : <Save />} &nbsp; Tambahkan Usaha </Button>
            </div>
        </div>
        <hr />
        <h3 className='text-primary'>Bulk Upload Usaha</h3>
        <p className='text-secondary'>
            Upload User secara bulk memungkinkan admin mengupload daftar user dalam jumlah besar menggunakan file CSV yang divalidasi oleh sistem. 
            User ditambahkan ke database jika file valid, atau notifikasi error diberikan jika ada kesalahan. 
        </p>
        <DownloadTemplateUsahaBulk />
        <br />
        <br />
        
        {
            file === null && (<FileUploader 
                classes={"container border rounded"}
                children={dropArea()} 
                multiple={false} 
                handleChange={handleFileChange}  
                types={["XLS", "XLSX"]} 
            />)
        }

        {
            (file !== null && !isSubmittedSuccessfully) && (
                <div className='container border rounded text-center' style={{padding: "20px"}}>
                    <h4>{file.name}</h4>
                    <Button disabled={isSubmitting} variant='contained' color='primary' style={{marginRight: "5px"}} onClick={uploadTemplateUsahaBulk}>
                        Submit
                    </Button>
                    <Button disabled={isSubmitting} variant='contained' color='error' style={{marginLeft: "5px"}} onClick={clearSelectedFile}>
                        Batalkan
                    </Button>
                    {
                        isSubmitting && <div className='text-center' style={{marginTop: "20px"}}>
                            Uploading ...
                            <LinearProgress />
                        </div>
                    }

                    {/* {
                        errorMessage.length > 0 && <div className='text-center text-danger' style={{marginTop: "20px"}}>
                        <Error /><br />
                        {errorMessage}
                    </div>
                    } */}
                </div>
            )
        }

        {
            errorMessage.length > 0 && <Box sx={{marginTop: "20px", marginBottom: "10px", fontWeight: "bold"}}>
                Berikut daftar user yang belum dapat di upload, silakan perbaiki data excelnya 
            </Box>
        }

        {
            errorMessage?.map((item, index) => {
                return <Alert sx={{marginBottom: "3px"}} key={"alert" + index} severity='error'>{item}</Alert>
            })
        }
        
        {
            isSubmittedSuccessfully && (
                <div className='container border rounded text-center bg-success text-center text-white' style={{padding: "20px"}}>
                    <DoneAll /><br />
                    User berhasil diupload!
                </div>
            )
        }

        
    </>;
}

export default DialogTambahUsaha;