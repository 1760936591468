import React, { Component, useState } from 'react';
import "./DashBoardStoreManagement.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import ExportStores from './ExportStores';
import StoreTransactionList from './StoreTransactionList';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, LinearProgress, ListItemIcon, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, TextField, Tooltip } from '@mui/material';
import DownloadTemplateProductBulk from './DownloadTemplateProduct';
import { Add, Assignment, AttachMoney, Close, DoneAll, Error, Image, ImageOutlined, Info, InfoRounded, Logout, MoreVert, PictureAsPdf, PriceCheck, Search, Settings, Subscript, Tag } from '@mui/icons-material';
import { FileUploader } from 'react-drag-drop-files';
import DialogStoreInfoContent from './Dialogs/DialogStoreInfo';
import DialogAturSubscription from './Dialogs/DialogAturSubscription';
import DialogTambahUsaha from './Dialogs/DialogTambahUsaha';

import PieChart from '../GoogleAnalytics/PieChart';
import LineChart from '../GoogleAnalytics/LineChart';
import BarChart from '../GoogleAnalytics/BarChart';
import DateRangePicker from '../../lib/DateRangePicker';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class DashboardStoreManagement extends Component {
    state = { 
        columns : [
            {
                name: "Nama Toko",
                selector: row => row.nama_toko,
                sortable: true
            },
            {
                name: "Pemilik",
                selector: row => row.owner_name,
                sortable: true
            },
            {
                name: "No HP",
                selector: row => row.owner_ussd,
                sortable: true
            },
            {
                name: "Lokasi",
                selector: row => row.alamat,
                sortable: true
            },
            {
                name: "Join Date",
                selector: row => row.join_date,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.transaksi).toDate();
                    const b = moment(rowB.transaksi).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Transaksi Terakhir",
                selector: row => row.transaksi,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.transaksi).toDate();
                    const b = moment(rowB.transaksi).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Subscription",
                selector: row => row.subscribe_type,
                sortable: true
            },
            {
                name: "End On",
                selector: row => row.akhir_subscription,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.transaksi).toDate();
                    const b = moment(rowB.transaksi).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "",
                selector: row => row.action
            }
        ],
        data : [
            // ["Joe James", "Test Corp", "Yonkers", "NY"],
            // ["John Walsh", "Test Corp", "Hartford", "CT"],
            // ["Bob Herm", "Test Corp", "Tampa", "FL"],
            // ["James Houston", "Test Corp", "Dallas", "TX"],
        ], 
        //all, has transaction, no transaction
        filter: "all",
        currentStore: {},
        dialogOpen: false,
        dialogAturSUbscriptionOpen: false,
        dialogTambahUsahaOpen: false,
        selectedStore: null,
        snackbarOpen: false,
        snackbarMessage: "",
        anchorElMenu: null,
        showMenu: false,
        menustoreInfo: null,
        query: "",
        opsiFieldPencarian: "fullname",
        chartKategoriUser: {labels: [], datasets: []},
        chartUser: {labels: [], datasets: []},
        activeUsersData: {labels: [], datasets: []},
        jumlahUser: 0,
        startDate: moment(new Date()).subtract(1, "Y"),
        endDate: moment(new Date()),
    }

    options = {
        filterType: 'checkbox',
    };

    loadStores = () => {
    // loadUsers = () => {

        let paramFilter = {};

        if(this.state.filter === "all"){
            paramFilter = {};
        }

        if(this.state.filter === "has transaction"){

        }

        if(this.state.filter === "no transaction"){
            paramFilter = {last_transaction: null};
        }

        if(this.state.query && this.state.query.length > 0){
            let selectedField = this.state.opsiFieldPencarian;

            if(selectedField === "fullname"){
                paramFilter.nama_toko = {$regex: this.state.query, $options: "i"};
            }else if(selectedField === "ussd"){
                paramFilter.owner_ussd = {$regex: this.state.query, $options: "i"};
            }
            
        }

        let params = {
            filter: paramFilter,
            sort:{last_transaction: -1},
            limit:100
        };

    
        console.log("PARAMS STORES", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.getStoreList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("STORERESPONSE", response.data);
            const responseData = response.data;
            // const userDatas = [...this.state.data];
            const userDatas = [];
            
            responseData.data.forEach(element => {
                // console.log(element);
                // const userData = {
                //     alamat: element.alamat, 
                //     nama_toko: element.nama_toko, 
                //     transaksi: element.last_transaction !== undefined ? moment(element.last_transaction).format("DD-MM-YYYY HH:mm:ss") : null, 
                //     action: this.action(element)
                // };
                element.transaksi = element.last_transaction !== undefined ? moment(element.last_transaction).format("DD-MM-YYYY HH:mm:ss") : null;
                element.join_date = element.created_at !== undefined ? moment(element.created_at).format("DD-MM-YYYY") : null;
                element.akhir_subscription = element.valid_thru !== undefined ? moment(element.valid_thru).format("DD-MM-YYYY HH:mm:ss") : null;
                element.action = this.action(element)
                userDatas.push(element);
            });

            this.setState({data: userDatas});
            
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    componentDidMount() {
        this.loadStores(null);
        this.handleReload();
    }

    handleDateChanged = (rangeDate) => {

        // let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
        // let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
        let startDate =  moment(rangeDate[0]);
        let endDate =  moment(rangeDate[1]);
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {
            this.handleReload();
        });
    }

    handleChangeFilter = (filter) => {
        this.setState({filter: filter}, () => {
            this.loadStores();
        });
    }

    handleShowMenu = (event, storeInfo) => {
        this.setState({anchorElMenu: event.currentTarget, showMenu: true, menustoreInfo: storeInfo});
    }

    handleCloseMenu = (event, storeInfo) => {
        this.setState({anchorElMenu: null, showMenu: false, menustoreInfo: null});
    }

    getFilterClasses = (filter) => {
        let classes = "col-4";
        if(filter === this.state.filter){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    action = (storeInfo) => {
        return (
            <IconButton onClick={(event) => this.handleShowMenu(event, storeInfo)} color='primary'>
                <MoreVert />
            </IconButton>
        );
        // return (<div>
            {/* <Tooltip key={"tooltip1"} title="Lihat Transaksi"> */}
                {/* <span title='Lihat transaksi' style={{cursor: "pointer"}} onClick={this.toggleDrawer(true, storeInfo)} className="text-primary"><Assignment /> </span> */}
            {/* </Tooltip> */}
            {/* &nbsp; */}
            {/* <Tooltip key={"tooltip2"} title="Download template upload product bulk"> */}
                {/* <DownloadTemplateProductBulk storeId={storeInfo.id} /> */}
            {/* </Tooltip> */}
            {/* &nbsp; */}
            {/* <span  title="Detail Toko" style={{cursor: "pointer"}} className="btna btna-primary btna-sm text-primary" onClick={() => this.showDialogInfo(storeInfo)}><Info /></span>
            <span  title="Atur Subscription" style={{cursor: "pointer"}} className="btna btna-primary btna-sm text-primary" onClick={() => this.showDialogAturSubscription(storeInfo)}><AttachMoney /></span> */}
            {/* <span  title="Tambah Usaha" style={{cursor: "pointer"}} className="btna btna-primary btna-sm text-primary" onClick={() => this.showDialogTambahUsaha(storeInfo)}><AttachMoney /></span> */}
            
            
        // </div>);
    }

    toggleDrawer = (open, storeInfo) => (event) => {
        console.log(event);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({right: open, currentStore: storeInfo}, ()=>{
            
        });
    };

    showDialogInfo = (storeInfo) => {
        this.setState({selectedStore: storeInfo, dialogOpen: true});
    }

    showDialogAturSubscription = (storeInfo) => {
        this.setState({selectedStore: storeInfo, dialogAturSUbscriptionOpen: true});
    }

    showDialogTambahUsaha = (storeInfo) => {
        this.setState({selectedStore: storeInfo, dialogTambahUsahaOpen: true});
    }

    handleShowSnackbar = (message) => {
        this.setState({snackbarOpen: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarOpen: false, snackbarMessage: ""});
    }

    handleQueryChange = (event) => {

        let value = event.target.value && event.target.value.length > 0 ? event.target.value : "";

        this.setState({query: value})
    }

    onKeyDownHandler = (e) => {

        if(e.keyCode === 13){
            this.loadStores();
        }

        // if(e.keyCode === 13 && e.ctrlKey){
        //     this.props.onSendMessage();
        //     console.log("CTRL + ENTER");
        // }else if(e.keyCode === 13 && e.shiftKey){
        //     this.props.onSendMessage();
        //     console.log("CTRL + SHIFT");
        // }else if (e.keyCode === 13) {
        //     console.log("ENTER");
        // }
    };

    handleOpsiFieldPencarianChange = (event) => {
        console.log("OPSI", event);
        this.setState({opsiFieldPencarian: event.target.value}, () => {
            this.loadStores();
        })
    }

    handleReload = () => {
        this.loadUserSupportData();
        this.gaReportActiveusers();
    }

    loadUserSupportData = () => {

        const params = {
          filter: {
            //   dateStart: this.state.startDate,
            //   dateEnd: this.state.endDate
            dateStart: this.state.startDate.format("YYYY-MM-DD"),
            dateEnd: this.state.endDate.format("YYYY-MM-DD"),
          },
          sort: {
              _id: -1
          },
          limit: 20
        };
    
        axios
          .post(
            Api.storeSupport(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
    
            const data = response.data;
    
            //Kategori Langganan
            const kategoriUser = data.chartKategoriToko            ;
            const cloneChartKategoriUser = {...this.state.chartKategoriUser};
            let datasetsData = [];
            cloneChartKategoriUser.datasets = [];
            cloneChartKategoriUser.labels = [];
            kategoriUser.forEach(item => {
              cloneChartKategoriUser.labels.push(item._id.subscribe_type);
              datasetsData.push(item.count);
            });
    
            cloneChartKategoriUser.datasets = [
              {
                label: "",
                data: datasetsData,
                backgroundColor: this.colors(),
                // backgroundColor: "#165BAA",
              }
            ];
            // End kategori langganan
    
            //Jumlah Toko
            const chartUser = data.chartToko;
            const cloneChartUser = {...this.state.chartUser};
            let datasetsDataUser = [];
            cloneChartUser.datasets = [];
            cloneChartUser.labels = [];
            let jumlahUser = 0;

            let usersByRole = [];//{roleName: "", byYearMonth: []}
            chartUser.forEach(item => {
                let role = item._id.subscribe_type;
                let findRole = usersByRole.find(byRole => byRole.roleName === role);

                const dataByYearMonth = {
                    year: item._id.year,
                    month: item._id.month,
                    role: item._id.subscribe_type,
                    count: item.count,
                };

                if(findRole){
                    findRole.byYearMonth.push(dataByYearMonth);
                    findRole.counts.push(dataByYearMonth.count);
                }else{
                    usersByRole.push({
                        roleName: role,
                        byYearMonth: [dataByYearMonth],
                        counts: [dataByYearMonth.count]
                    });
                }

            });

            chartUser.forEach(item => {
              let splitYear = `${item._id.year}`.split("");
              cloneChartUser.labels.push(`${splitYear[2]}${splitYear[3]}-${item._id.month}`);
            //   datasetsDataUser.push(item.count);
              jumlahUser += item.count;
            });

            cloneChartUser.labels.sort();
            
            usersByRole.forEach((item, index) => {

                let defaultData = [];
                cloneChartUser.labels.forEach(clone => {
                    defaultData.push(0);
                });

                item.byYearMonth.forEach(by => {
                    let splitYear = `${by.year}`.split("");
                    let keyLabel = `${splitYear[2]}${splitYear[3]}-${by.month}`;

                    let findIndex = cloneChartUser.labels.findIndex(label => label === keyLabel);

                    if(findIndex >= 0){
                        defaultData[findIndex] = by.count;
                    }

                });

                cloneChartUser.datasets.push(
                    {
                      label: item.roleName,
                      data: defaultData,
                      backgroundColor: this.colors()[index], 
                      // backgroundColor: "#165BAA",
                    }
                );
            });

            this.setState({
              chartKategoriUser: cloneChartKategoriUser, 
              chartUser: cloneChartUser, 
              jumlahUser: jumlahUser,
            //   storeTransactions: storeTrans
            }, () => {
              console.log("LOG___USERS", this.state.chartKategoriUser, "chartUser", this.state.chartUser);
            });
    
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
    }

    gaReportActiveusers = () => {
        const params = {
          dateRanges: [
            {
            //   startDate: this.state.startDate,
            //   endDate: this.state.endDate,
              startDate: this.state.startDate.format("YYYY-MM-DD"),
              endDate: this.state.endDate.format("YYYY-MM-DD"),
            },
          ],
          dimensions: [
            {
              name: 'city',
            },
            {
              name: 'country',
            },
          ],
          metrics: [
            {
              name: 'activeUsers',
            },
          ],
        };
    
        console.log("GAREPORT_PARAMS", params);
  
        this.setState({isLoading: true}, () => {
          axios
          .post(
            Api.gaReport(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("GAREPORT", response.data);
  
            var totalActiveUsers = 0;
            var dataActiveUsers = [];
            var labels = [];
            var data = [];
            var i = 1;
            var plainData = [];
            response.data.rows.forEach(element => {
              
              // dataActiveUsers.push(
              //   {
              //       label: element.dimensionValues[0].value,
              //       data: element.metricValues[0].value,
              //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
              //   }
              // );
              if(i <= 10){
                data.push(element.metricValues[0].value);
                labels.push(element.dimensionValues[0].value);
                plainData.push({dimension: element.dimensionValues[0].value, metric: element.metricValues[0].value});
                ++i;
              }
              
              totalActiveUsers += parseInt(element.metricValues[0].value);
            });
  
            dataActiveUsers.push(
              {
                  label: "",
                  data: data,
                  // backgroundColor: this.colors(),
                  backgroundColor: "#165BAA",
              }
          );
  
            this.setState({isLoading: false, activeUsersPlainData: plainData, totalActiveUsers: totalActiveUsers, gaReportData: response.data.rows, activeUsersData: {labels: labels, datasets: dataActiveUsers}});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
    };

    colors = () => {
        return [
          'rgba(28, 254, 156, 0.7)',
          'rgba(244, 182, 232, 0.7)',
          'rgba(88, 224, 68, 0.7)',
          'rgba(13, 133, 22, 0.7)',
          'rgba(66, 21, 49, 0.7)',
          'rgba(15, 61, 144, 0.7)',
          'rgba(63, 71, 53, 0.7)',
          'rgba(110, 47, 103, 0.7)',
          'rgba(251, 143, 5, 0.7)',
          'rgba(230, 65, 106, 0.7)',
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ];
      };

    render() { 
        return ( 
            <div className="container-fluid no-padding">

            {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                    <Drawer anchor={anchor} open={this.state[anchor]} 
                        onClose={this.toggleDrawer(false,0)}
                        >
                        <StoreTransactionList 
                            storeInfo={this.state.currentStore}
                            // onClose={this.toggleDrawer(false, 0)}
                        />
                    </Drawer>
                </React.Fragment>
            ))}

            <Snackbar
                open={this.state.snackbarOpen}
                autoHideDuration={4000}
                onClose={this.handleCloseSnackbar}
                message={this.state.snackbarMessage}
            />

            <Menu
                anchorEl={this.state.anchorElMenu}
                id="account-menu"
                open={this.state.showMenu}
                onClose={this.handleCloseMenu}
                onClick={this.handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={this.toggleDrawer(true, this.state.menustoreInfo)}>
                    <ListItemIcon>
                        <Assignment color='secondary' fontSize='small' />
                    </ListItemIcon>
                    Lihat Transaksi
                </MenuItem>
                <MenuItem onClick={() => this.showDialogInfo(this.state.menustoreInfo)}>
                    <ListItemIcon>
                        <Info color='secondary' fontSize='small' />
                    </ListItemIcon>
                    Detail Toko
                </MenuItem>
                <MenuItem onClick={() => this.showDialogAturSubscription(this.state.menustoreInfo)}>
                    <ListItemIcon>
                        <AttachMoney color='secondary' fontSize="small" />
                    </ListItemIcon>
                    Atur Subscription
                </MenuItem>
            </Menu>

            {
                (this.state.selectedStore !== null && this.state.dialogOpen === true) && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={this.state.dialogOpen}
                        onClose={() => {this.setState({dialogOpen: false})}}
                    >
                        <DialogTitle>
                            <div className="row">
                                <div className="col-11">
                                    <h3 className='text-primary'>Detail Toko</h3>
                                </div>
                                <div className="col-1 text-right">
                                    <IconButton color='error' onClick={() => {this.setState({selectedStore: null, dialogOpen: false})}}><Close /></IconButton>
                                </div>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>
                            {
                                this.state.selectedStore !== null && <DialogStoreInfoContent storeInfo={this.state.selectedStore} showSnackbar={this.handleShowSnackbar}/>
                            }
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                ) 
            }

            {
                (this.state.selectedStore !== null && this.state.dialogAturSUbscriptionOpen === true) && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={this.state.dialogAturSUbscriptionOpen}
                        onClose={() => {this.setState({dialogAturSUbscriptionOpen: false})}}
                    >
                        <DialogTitle>
                            <div className="row">
                                <div className="col-11">
                                    <h3 className='text-primary'>Atur Subscription</h3>
                                </div>
                                <div className="col-1 text-right">
                                    <IconButton color='error' onClick={() => {this.setState({selectedStore: null, dialogAturSUbscriptionOpen: false})}}><Close /></IconButton>
                                </div>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>
                            {
                                this.state.selectedStore !== null && <DialogAturSubscription storeInfo={this.state.selectedStore} showSnackbar={this.handleShowSnackbar}/>
                            }
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                ) 
            }
            {
                (this.state.selectedStore !== null && this.state.dialogTambahUsahaOpen === true) && (
                    <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={this.state.dialogTambahUsahaOpen}
                        onClose={() => {this.setState({dialogTambahUsahaOpen: false})}}
                    >
                        <DialogTitle>
                            <div className="row">
                                <div className="col-11">
                                    <h3 className='text-primary'>Tambah Usaha</h3>
                                </div>
                                <div className="col-1 text-right">
                                    <IconButton color='error' onClick={() => {this.setState({selectedStore: null, dialogTambahUsahaOpen: false})}}><Close /></IconButton>
                                </div>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>
                            {
                                this.state.selectedStore !== null && <DialogTambahUsaha storeInfo={this.state.selectedStore} showSnackbar={this.handleShowSnackbar} />
                            }
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog>
                ) 
            }

                {/* <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                            <h3 className="mt-4">Store Management</h3>
                        </div>
                        <div className="col-md-8 col-lg-8  col-sm-12 col-xs-12">
                            <p className="mt-4">
                            Sebagai halaman monitoring dan manajemen pengelolaan<br />user dari aplikasi<br />
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* Start of filter */}
                <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col-4">
                        {/* <TextField 
                            onChange={this.handleKeywordChange}
                            id="txt-keyword" 
                            value={this.state.keyword} 
                            fullWidth 
                            label="Keyword" variant="outlined" 
                            size='small'
                        /> */}
                    </div>
                    <div className="col-8" style={{backgroundColor: ""}}>
                    <div className="row">
                        <div className="col-3">
                            {/* <FormControl size='small' fullWidth>
                                <InputLabel id="cb-lokasi">Lokasi</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={"Semua"}
                                    label="Lokasi"
                                    onChange={(event) => {}}
                                    >
                                    <MenuItem value={"Semua"}>Semua</MenuItem>
                                    <MenuItem value={20}>?</MenuItem>
                                    <MenuItem value={30}>??</MenuItem>
                                </Select>
                            </FormControl> */}
                        </div>
                        <div className="col-6 text-center">
                            <small>Durasi</small>&nbsp;
                            <MyDateRangePicker onDateChanged={this.handleDateChanged} startDate={this.state.startDate} endDate={this.state.endDate} />
                            {/* <MyDateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onDateChanged={this.handleDateChanged}/> */}
                        </div>
                        <div className="col-3">
                            <ExportStores data={this.state.data} />
                            {/* <Button fullWidth variant='outlined' color='primary' size='small'>
                                Export <PictureAsPdf />
                            </Button> */}
                        </div>
                    </div>
                    </div>
                </div>
                {/* End of filter */}

                <div className="row">
                    <div className="col-4">
                        <Paper elevation={2} sx={{padding: "10px"}}>
                            <div className="row" style={{padding: "10px"}}>
                                <div className="col-10" style={{color: "#A1A1A1"}}>
                                    <Box style={{fontWeight: "bold"}}>
                                        Kategori Toko
                                    </Box>
                                    <Box>
                                        <small>Toko berdasarkan langganan</small>
                                    </Box>
                                </div>
                                <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                            </div>
                            <Divider />
                            <PieChart data={this.state.chartKategoriUser} />
                        </Paper>
                    </div>
                    <div className="col-4">
                        <Paper elevation={2} sx={{padding: "10px"}}>
                            <div className="row" style={{padding: "10px"}}>
                                <div className="col-10" style={{color: "#A1A1A1"}}>
                                    <Box style={{fontWeight: "bold"}}>
                                        Jumlah Toko
                                    </Box>
                                    <Box>
                                        <small>{this.state.jumlahUser}</small><br />
                                        <small>Toko berdasarkan role</small>
                                    </Box>
                                </div>
                                <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                            </div>
                            <Divider />
                            <LineChart data={this.state.chartUser} />
                        </Paper>
                    </div>
                    <div className="col-4">
                    <Paper elevation={2} sx={{padding: "10px", mt: "0px", mb: "20px"}}>
                        <div className="row" style={{padding: "10px"}}>
                        <div className="col-10" style={{color: "#A1A1A1"}}>
                            <Box style={{fontWeight: "bold"}}>
                                Lokasi Toko
                            </Box>
                            <Box>
                                <small>Top 5 toko berdasarkan lokasi</small>
                            </Box>
                        </div>
                        <div className="col-1" style={{textAlign: "center"}}><InfoRounded color='disabled' /></div>
                        </div>
                        <BarChart data={this.state.activeUsersData} />
                    </Paper>
                    </div>
                </div>
                <br />
                <div className="container-fluid dashboard-content">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <Button size='small' variant='contained' color='primary' onClick={this.showDialogTambahUsaha}><Add /> Tambah Usaha</Button>
                            {/* <small>Tampilkan hasil untuk :</small> 
                            <br />
                            <div className="hidden-sm hidden-xs visually-hidden">
                            {this.state.filter === "all" ? "Semua" : this.state.filter === "has transaction" ? "Sudah ada transaksi" : "Belum ada transaksi"}
                            </div> */}
                            
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div className="row filter-container">
                                <div className={this.getFilterClasses("all")} onClick={() => this.handleChangeFilter("all")}>
                                    Semua
                                </div>
                                <div className={this.getFilterClasses("has transaction")} onClick={() => this.handleChangeFilter("has transaction")}>
                                    Ada Transaksi
                                </div>
                                <div className={this.getFilterClasses("no transaction")} onClick={() => this.handleChangeFilter("no transaction")}>
                                    Tanpa Transaksi
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <div className="row">
                        <div className="col-122">
                            <Box sx={{display: "flex", flexDirection: "row"}}>
                                <div style={{paddingTop: "6px", fontWeight: "bold", marginRight: "15px", marginLeft: "15px"}}>Opsi Pencarian</div>
                                <FormControl>
                                    {/* <FormLabel id="demo-radio-buttons-group-label">Opsi Pencarian</FormLabel> */}
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="fullname"
                                        name="radio-buttons-group"
                                        row
                                        onChange={this.handleOpsiFieldPencarianChange}
                                    >
                                        <FormControlLabel value="fullname" control={<Radio size='small' />} label="Nama Toko" />
                                        <FormControlLabel value="ussd" control={<Radio size='small' />} label="No HP" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11">
                            
                            <TextField
                                id="txtQuery"
                                label={"Pencarian " + (this.state.opsiFieldPencarian === "fullname" ? "nama toko" : "no hp")}
                                defaultValue={""}
                                type='text'
                                fullWidth
                                size='small'
                                onChange={this.handleQueryChange}
                                onKeyDown={this.onKeyDownHandler}
                            />
                        </div>
                        <div className="col-1" style={{paddingTop: "4px"}}>
                            <Button onClick={this.loadStores} color='primary' size='small' fullWidth variant='contained'><Search /> Cari</Button>
                        </div>
                    </div>
                    <br />

                    <DataTable 
                        title={""} 
                        data={this.state.data} 
                        columns={this.state.columns} 
                        options={this.options} 
                        pagination
                        // expandableRows
                    />
                </div>
                
            </div>

         );
    }
}
 
export default DashboardStoreManagement;

function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState(
      props.startDate && props.endDate ? [props.startDate.toDate(), props.endDate.toDate()] :
      [moment(new Date()).subtract(1, "Y").toDate(), Date.now()]
    );
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
  
    return (
      <>
        <ReactDatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              // if(diffs > 35){
              //   setDateRange([null, null]);
              //   setMessage("Rentang tanggal max 35 hari");
              //   return
              // }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }
  
          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
          style={{borderColor: "#FFFFFF"}} 
          wrapperClassName="my-datepicker"
          className='border rounded rounded-lg border-secondary p-1 text-center'
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }

