import React from 'react';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          placeholderText='Filter tanggal'
          onChange={(update) => {
            console.log("RANGE", update);
            
            // if(update[0] !== null && update[1] !== null){
            //   let startDate = moment(update[0]);
            //   let endDate = moment(update[1]);
            //   let diffs = Math.abs(startDate.diff(endDate, "days"));
            //   console.log("DIFFS", diffs);
            //   if(diffs > 35){
            //     setDateRange([null, null]);
            //     setMessage("Rentang tanggal max 35 hari");
            //     return
            //   }
            // }
            
            setDateRange(update);
            setMessage("");
            // if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            // }

          }}
          isClearable={true}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
          className='border rounded rounded-lg border-secondary p-2 text-center'
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }

  export default DateRangePicker;