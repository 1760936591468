import React from "react";
// import "./styles.css";

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, PointElement, LineElement } from 'chart.js';

ChartJS.register(Tooltip, Legend, PointElement, LineElement);

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },
  ]
};

export default function LineChart(props) {
  return (
    <div className="App">
      <Bar data={props?.data}/>
    </div>
  );
}
